<template>
  <div>
    <b-modal
      id="modal-add-link"
      centered
      :title="title"
      size="lg"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <div>
          <div>
            <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="handleSubmit(Link)"
              @reset.prevent="resetForm"
            >
              <b-form-group>
                <b-button
                  variant="success"
                  class="add-btn"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Website Link</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                  variant="info"
                  @click.prevent="showDropboxPopup"
                >
                  <span class="brand-logo mr-25">
                    <b-img
                      :src="DropBoxIcon"
                      alt="logo"
                      class="logo"
                    />
                    <span>Dropbox</span>
                  </span>
                </b-button>
              </b-form-group>
              <!-- Row Loop -->
              <b-row
                v-for="(l, index) in links"
                :id="l.id"
                :key="l.id"
                ref="row"
                eslint-disable-next-line
                vue
                no-use-v-if-with-v-for
              >

                <!-- Item Name -->
                <b-col>
                  <!-- Name -->
                  <validation-provider
                    #default="validationContext"
                    name="url"
                    rules="url|required"
                  >
                    <h6>Link</h6>
                    <b-form-group>
                      <b-form-input
                        id="url"
                        v-model="l.value"
                        type="url"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter webpage link"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Cost -->
                <b-col>
                  <!-- Column Select -->
                  <validation-provider
                    #default="validationContext"
                    name="Title"
                    rules="required"
                  >
                    <h6>Title</h6>
                    <b-form-group>
                      <b-form-input
                        id="matrix-1"
                        v-model="l.name"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter webpage title"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
              </b-row>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click="handleSubmit()"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hideAddLinkModal()"
                >
                  Cancel
                </b-button>
              </div>

            </b-form>
          </div>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BModal,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    entity: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    docs: {
      type: Array,
      default: () => [],
    },
    link: { type: Function, default: () => '' },
  },
  data() {
    return {
      links: [],
      nextTodoId: 1,
    }
  },
  setup() {
    const { DropBoxIcon } = $themeConfig.icons
    return {
      DropBoxIcon,
    }
  },
  watch: {
    docs() {
      this.links = []
      if (this.links && this.links.length !== 0) {
        this.nextTodoId = this.docs.at(-1).id
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    repeateAgain() {
      this.links.push({
        id: this.nextTodoId += Math.trunc(Date.now() / 20000000000),
      })
    },
    removeItem(index) {
      this.links.splice(index, 1)
    },
    hideAddLinkModal() {
      this.resetForm()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-link')
      })
    },
    Link() {
      this.hideAddLinkModal()
      this.link(this.links)
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      this.name = ''
      this.value = ''
    },
    pushArrayOfLinks(values) {
      values.forEach(value => {
        this.links.push({
          id: this.nextTodoId += 1,
          name: value.title,
          value: value.link,
        })
      })
    },
    showDropboxPopup() {
      if (window.Dropbox) {
        window.Dropbox.choose({
          // Required. Called when a user selects an item in the Chooser.
          success: async files => {
            const attachments = []
            for (let i = 0; i < files.length; i += 1) {
              const attachment = {}
              attachment.id = files[i].id
              attachment.title = files[i].name
              attachment.size = files[i].bytes
              attachment.iconURL = files[i].icon
              attachment.link = files[i].link
              attachment.extension = `. ${files[i].name.split('.')[1]}`
              attachments.push(attachment)
            }
            this.tempAttachments = attachments
            this.pushArrayOfLinks(attachments)
          },

          // Optional. Called when the user closes the dialog without selecting a file
          // and does not include any parameters.
          cancel() {

          },

          linkType: 'preview', // or "direct"

          multiselect: true, // or true

          extensions: ['.pdf', '.doc', '.docx'],

          folderselect: false, // or true
        })
      }
    },
  },
}
</script>

<style scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.logo{
  width: 14px;
  height: 14px;
  margin-right: 3.5px;
}

.add-btn{
  margin-right: 15px;
}
</style>
