<template>
  <div>
    <process-add
      :is-sidebar-active.sync="isAddNewProcessSidebarActive"
      :is-readonly.sync="isAddNewProcessSidebarInDisplayMode"
      :value.sync="activeRecord"
      :edit-tab="editRecord"
    />
    <ImportCsvModal
      entity="process"
      title="Import Processes"
      @import-success="onImportSuccess"
    />

    <!-- Table Container Card -->
    <div
      no-body
      class="mb-0 container-card"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div
              class="d-flex align-items-center justify-content-between"
              style="flex-wrap: wrap"
            >
              <transition name="slide-fade">
                <entity-filter
                  entity="process"
                  entity-plural-slug="processes"
                  :entity-field-suggestions="PROCESS_ENTITY_FIELD_SUGGESTIONS"
                  :search-query.sync="searchQuery"
                  :selected-queries.sync="selectedQueries"
                  @clear-query="clearQuery"
                />
              </transition>

              <transition name="slide-fade">
                <div v-if="selectedItems.length !== 0">
                  <!-- Delete Selected Items Button -->
                  <b-button
                    class="ml-1"
                    variant="danger"
                    @click="deleteSelectedRecords"
                  >
                    <span class="text-nowrap">Delete</span>
                  </b-button>
                </div>
              </transition>
              <b-button
                v-if="$can('add', 'process') || $can('add', 'myProcess')"
                variant="primary"
                class="import-btn"
                style="margin-left: 20px"
                @click="editRecord(null)"
              >
                <span>Add Process</span>
              </b-button>
              <b-button
                v-if="$can('import', 'process')"
                variant="primary"
                class="import-btn"
                style="margin-left: 20px"
                @click="openImportCsvModal()"
              >
                <span>Import Processes</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="table"
        ref="refProcessListTable"
        class="position-relative table"
        :items="
          currentRouteName === 'my-process-list'
            ? currentUserProcesses.nodes
            : processes.nodes
        "
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="isLoading"
        show-empty
        hover
        empty-text="No matching records found"
        @row-clicked="displayRecord"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              type="grow"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <!-- Header: Select -->
        <template #head(select)="[]">
          <div class="d-flex">
            <b-form-checkbox
              style="text-align: center !important; margin-left: 5px"
              :checked="
                processes.nodes.length !== 0 &&
                  processes.nodes.length === selectedItems.length
              "
              @change="selectOrUnselectAllRows($event)"
            />
          </div>
        </template>

        <!-- Column: Select -->
        <template #cell(select)="data">
          <template>
            <b-form-checkbox
              style="text-align: center !important; margin-left: 5px"
              :checked="
                selectedItems.find((e) => e.id === data.item.id) ? true : false
              "
              @change="selectOrUnselectRow($event, data.item)"
            />
          </template>
        </template>

        <!-- Column: ID -->
        <template #cell(processHandle)="data">
          <span>{{ data.item.processHandle }}</span>
        </template>

        <!-- Column: ID -->
        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
        </template>

        <!-- Column: doc -->
        <template #cell(status)="data">
          <b-popover
            v-if="data.item.docs && data.item.docs.length > 0"
            :target="'popover-button-' + data.index + 1"
            placement="top"
            triggers="hover"
          >
            <span v-if="data.item.docs">
              Docs: {{ data.item.docs.length }}
            </span>
            <span v-else> Docs: 0 </span>
          </b-popover>
          <feather-icon
            v-if="data.item.docs && data.item.docs.length > 0"
            :id="'popover-button-' + data.index + 1"
            style="color: deepskyblue"
            class="custom-icon"
            icon="FileTextIcon"
            size="16"
            @click="hidePopover(data.index + 1)"
          />
          <feather-icon
            v-if="isFrequencyIconShow(data.item) == 'ok'"
            class="custom-icon text-success"
            icon="RefreshCcwIcon"
            size="16"
          />
          <img
            v-if="isFrequencyIconShow(data.item) === 'warning'"
            :src="recycle"
            alt="recycle logo"
            class="logo"
          >
        </template>

        <!-- Column: Labels -->
        <template #cell(labels)="data">
          <span
            v-for="item in data.item.labels"
            :key="item.id"
          >
            <b-badge
              pill
              variant="light-success"
              class="mr-1"
            >
              {{ item.label }}
            </b-badge>
            <!-- <b-badge
              v-if="count <= 2"
              pill
              variant="light-success"
              class="mr-1"
            >
              {{ item.label }}
            </b-badge> -->
            <!-- <b-badge
              v-show="count > 2 && count < 4"
              pill
              variant="light-success"
              class="mr-1"
            >
              +{{ data.item.labels.length - count }}
            </b-badge> -->
          </span>
        </template>

        <!-- Column: Owner Role -->
        <template #cell(ownerRole)="data">
          <span v-if="data.item.role">
            {{ data.item.role.roleHandle }}
          </span>
        </template>

        <!-- Column: ASSIGNEE  -->
        <template #cell(ASSIGNEE)="data">
          <span
            v-for="(handle, count) in data.item.assignedRoles"
            :key="handle.id"
          >
            <b-badge
              v-if="count <= 2"
              pill
              variant="light-primary"
              style="margin-right: 1px"
            >
              {{ handle.roleHandle }}
            </b-badge>
            <b-badge
              v-show="count > 2 && count < 4"
              pill
              variant="light-primary"
              style="margin-right: 1px"
            >
              +{{ data.item.assignedRoles.length - count }}
            </b-badge>
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="action-buttons">
            <b-button
              v-if="$can('edit', 'process')"
              :id="`row-${data.item.id}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Edit"
              class="cursor-pointer"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <b-button
              v-if="$can('delete', 'process')"
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of
              {{ paginationMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="
                currentRouteName === 'my-process-list'
                  ? currentUserProcesses.totalCount
                  : processes.totalCount
              "
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 vue-select-up"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BTooltip,
  BSpinner,
  BBadge,
  VBTooltip,
  BPopover,
  BFormCheckbox,
  VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'vue-debounce'
import { mapState, mapActions } from 'vuex'
import { selectPosition } from '@core/mixins/ui/select'
import ImportCsvModal from '@/views/shared/ImportCsvModal.vue'
import { PROCESS_ENTITY_FIELD_SUGGESTIONS } from '@/utils/entity-filter'
import EntityFilter from '@/views/shared/Filter.vue'
import ProcessAdd from './ProcessAdd.vue'

export default {
  components: {
    // ProcessListFilters,
    ProcessAdd,
    ImportCsvModal,
    EntityFilter,
    BPopover,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,
    BBadge,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  mixins: [selectPosition],
  data() {
    return {
      tableColumns: [
        {
          key: 'select',
          stickyColumn: true,
          label: 'select',
          class: 'select-edit',
        },
        {
          key: 'processHandle',
          sortable: true,
          label: 'ID',
          class: 'my-process-class',
        },
        { key: 'name', sortable: true, class: 'my-main-class name-col' },
        { key: 'status', sortable: false, class: 'my-main-class status-col' },
        {
          key: 'labels',
          sortable: true,
          label: 'Labels',
          class: 'my-main-class label-col',
        },
        {
          key: 'ownerRole',
          sortable: true,
          label: 'Owner',
          class: 'my-main-class owner-col',
        },
        {
          key: 'ASSIGNEE',
          sortable: true,
          label: 'ASSIGNEE',
          class: 'my-main-class label-col',
        },
        { key: 'actions', class: 'my-main-class action-btns' },
      ],
      isAddNewProcessSidebarActive: false,
      isAddNewProcessSidebarInDisplayMode: false,
      activeRecord: null,
      searchFilter: {
        processType: null,
      },
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,

      sortBy: 'processHandle',
      isSortDirDesc: false,

      // FOR AUTO SUGGEST
      PROCESS_ENTITY_FIELD_SUGGESTIONS,
      searchQuery: [],
      selectedQueries: [],

      // For selecting table rows
      selectedItems: [],
    }
  },
  computed: {
    ...mapState('process', [
      'processes',
      'currentUserProcesses',
      'isLoading',
      'fieldValues',
    ]),
    ...mapState('filter', ['filtersAccessibleToCurrentUser']),
    paginationMeta() {
      let processesToPaginate = { nodes: [], totalCount: 0 }
      if (this.currentRouteName === 'process-list') processesToPaginate = this.processes
      else if (this.currentRouteName === 'my-process-list') processesToPaginate = this.currentUserProcesses
      return {
        from:
          this.perPage * (this.currentPage - 1)
          + (processesToPaginate.nodes.length ? 1 : 0),
        to:
          this.perPage * (this.currentPage - 1)
          + processesToPaginate.nodes.length,
        of: processesToPaginate.totalCount,
      }
    },
    queryParams() {
      return {
        processType: this.searchFilter.processType,
        q: this.searchQuery,
        offset: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        sortBy: this.sortBy,
        isDesc: this.isSortDirDesc,
      }
    },
    currentRouteName() {
      return this.$route.name
    },
    isSelectAllChecked: {
      get() {
        return this.processes.nodes.length === this.selectedItems.length
      },
      set(newValue) {
        this.selectOrUnselectAllRows(newValue)
      },
    },
  },
  watch: {
    queryParams: {
      handler() {
        this.fetchProcesses()
      },
      deep: true,
    },
    currentRouteName() {
      this.$store.dispatch('process/resetProcesses')
      this.fetchProcesses()
    },
    processes: {
      handler() {
        const { compact } = this.$route.query
        if (compact && this.processes.nodes[0]) {
          this.displayRecord(this.processes.nodes[0])
        }
      },
    },
  },
  created() {
    this.onLoad()
  },
  methods: {
    ...mapActions('process', [
      'getProcesses',
      'getCurrentUserProcesses',
      'getPossibleValuesForFieldFromProcessEntity',
      'getProcessesStatsItems',
    ]),
    ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
    refetchData: debounce(function cb() {
      this.fetchProcesses()
    }, '300ms'),
    onLoad() {
      let assignQuery = []
      const { q } = this.$route.query

      if (q) {
        if (Array.isArray(q)) {
          assignQuery = q
        } else {
          assignQuery = [q]
        }

        this.searchQuery = assignQuery
        this.selectedQueries = assignQuery
      }

      this.fetchProcesses()

      this.suggestions = this.fieldSuggestions
      this.getFiltersAccessibleToCurrentUser({ associatedEntities: ['Process'] })
    },
    fetchProcesses() {
      if (this.currentRouteName === 'process-list') {
        this.getProcesses(this.queryParams)
      } else if (this.currentRouteName === 'my-process-list') {
        this.getCurrentUserProcesses(this.queryParams)
      }
    },
    isFrequencyIconShow(item) {
      const { frequency, frequencyType } = item
      if (frequencyType === 'AsNeeded') {
        return false
      }
      if (frequency === null && frequencyType === 'Scheduled') {
        return 'warning'
      }

      if (frequency === null) {
        return false
      }

      const keys = Object.keys(frequency)
      const checkValues = this.frequencyKeys(keys, frequency)

      if (checkValues === false && frequencyType === 'Scheduled') {
        return 'warning'
      }

      return 'ok'
    },

    frequencyKeys(keys, parentArr) {
      let isValue = false
      keys.forEach(key => {
        const innerObj = parentArr[key]
        if (innerObj) {
          const innerValues = Object.values(innerObj)
          innerValues.forEach(value => {
            if (value && value.length > 0) {
              isValue = true
            }
          })
        }
      })
      return isValue
    },

    hidePopover(index) {
      this.$root.$emit('bv::hide::popover', `popover-button-${index}`)
    },

    editRecord(activeRecord) {
      const data = { ...activeRecord }
      this.activeRecord = data
      this.isAddNewProcessSidebarActive = true
      this.isAddNewProcessSidebarInDisplayMode = false
    },
    displayRecord(activeRecord) {
      this.editRecord(activeRecord)
      this.isAddNewProcessSidebarInDisplayMode = true
    },
    deleteRecord(activeRecord) {
      this.$bvModal
        .msgBoxConfirm(
          'Are you sure that you want to delete this process? \nThis action will delete all related tasks.',
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.$store.dispatch('process/removeProcess', activeRecord.id)
          }
        })
    },
    openImportCsvModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modal-import-csv')
      })
    },
    // for multi-select feature
    selectOrUnselectRow($event, record) {
      if ($event) {
        this.selectedItems.push(record)
      } else {
        const recordToDeleteIndex = this.selectedItems.findIndex(
          e => e.id === record.id,
        )
        this.selectedItems.splice(recordToDeleteIndex, 1)
      }
    },
    selectAllRows() {
      this.selectedItems = [...this.processes.nodes]
    },
    clearSelected() {
      this.selectedItems = []
    },
    selectOrUnselectAllRows(value) {
      if (value) this.selectAllRows()
      else this.clearSelected()
    },
    deleteSelectedRecords() {
      const selectedItemsIds = this.selectedItems.map(item => item.id)
      this.$store
        .dispatch('process/removeProcesses', selectedItemsIds)
        .then(() => {
          this.selectedItems = []
        })
    },
    truncate(str, n) {
      return str.length > n ? `${str.substr(0, n - 1)}...` : str
    },
    clearQuery() {
      this.$router.replace({ query: null })
    },
    onImportSuccess() {
      this.refetchData()
    },
  },
  setup() {
    const { recycle } = $themeConfig.icons
    return {
      recycle,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../@core/scss/vue/libs/vue-select";

.per-page-selector {
  width: 90px;
}

.action-buttons {
  margin-left: -10px;

  button {
    margin-right: 4px;
  }
}

.custom-icon {
  margin-right: 10px;
}

.logo {
  height: 17px;
  width: 17px;
  padding: 1px;
  margin-right: 10px;
}

.container-card {
  overflow: auto;
  background-color: white;
  border: none;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
}
</style>

<style lang="scss">
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-main-class {
  @extend .truncate;
  max-width: 100px;
  min-width: 80px;
  vertical-align: middle !important;
}

.select-edit {
  width: 50px !important;
  max-width: 50px !important;
  padding: 10.8px !important;
}

td {
  padding: 0.72rem !important;
}

.action-btns {
  white-space: pre-wrap !important;
  text-align: center;
}

th.action-btns {
  padding: 0px !important;
}

td.action-btns {
  @extend .action-btns;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.my-process-class {
  @extend .truncate;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

th.status-col {
  max-width: min-content !important;
  min-width: min-content !important;
  width: min-content !important;
  padding: 10px;
}

.status-col {
  min-width: 40px;
  width: 40px;
}

.name-col {
  min-width: 350px !important;
}

th.owner-col {
  padding: 10px;
}

.owner-col {
  min-width: fit-content !important;
  max-width: 80px !important;
}

@media only screen and (max-width: 600px) {
  .import-btn {
    max-width: 100px;
    font-size: 1rem;
  }
}

.label-col {
  max-width: 100px !important;
  white-space: normal !important;

  span {
    margin: 2px 2px 0px 0px !important;
  }
}
</style>
