<template>
  <b-modal
    id="modal-frequency"
    cancel-variant="outline-secondary"
    ok-title="Save"
    cancel-title="Cancel"
    centered
    :title="month && month.title"
    :visible="!!month"
    lazy
    :hide-footer="isReadonly"
    size="lg"
    @ok="$emit('save', internalValue)"
    @hidden="internalValue = getDefaultValue()"
    @show="beforeShow"
  >
    <b-card-group class="mb-0">
      <!-- card 1 -->
      <b-card
        class="by-date"
        no-body
      >
        <b-card-body>
          <b-card-title>By date</b-card-title>
          <b-card-text>
            <div class="row">
              <div
                v-for="n in daysOfMonth"
                :key="n"
                class="col"
              >
                <div class="cell-date">
                  <span>{{ n }}</span>
                  <b-form-checkbox
                    v-model="internalValue.days"
                    :value="n"
                    :disabled="isReadonly"
                  />
                </div>
              </div>
              <!-- <div
                v-for="l in ['Last']"
                :key="l"
                class="col"
              >
                <div class="cell-date">
                  <span>Last</span>
                  <b-form-checkbox
                    v-model="internalValue.days"
                    :value="l"
                    :disabled="isReadonly"
                  />
                </div>
              </div> -->
            </div>
          </b-card-text>
        </b-card-body>
      </b-card>

      <!-- card 2 -->
      <b-card
        class="by-day-of-week"
        no-body
      >
        <b-card-body>
          <b-card-title>By day of week</b-card-title>
          <b-card-text>
            <table class="day-of-week-table">
              <thead>
                <tr>
                  <th />
                  <th
                    v-for="dayOfWeek in daysOfWeek"
                    :key="dayOfWeek.value"
                  >
                    {{ dayOfWeek.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="frequencyOption in frequencyOptions"
                  :key="frequencyOption.value"
                >
                  <th>{{ frequencyOption.title }}</th>
                  <td
                    v-for="dayOfWeek in daysOfWeek"
                    :key="dayOfWeek.value"
                  >
                    <b-form-checkbox
                      v-model="internalValue[dayOfWeek.value]"
                      :value="frequencyOption.value"
                      :disabled="isReadonly"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-modal>
</template>

<script>
import {
  BCardGroup,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardGroup,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    month: {
      type: Object,
      default() {
        return null
      },
    },
    value: {
      type: Object,
      default() {
        return null
      },
    },
    isReadonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      daysOfWeek: [
        { title: 'Sun', value: 'sun' },
        { title: 'Mon', value: 'mon' },
        { title: 'Tue', value: 'tue' },
        { title: 'Wed', value: 'wed' },
        { title: 'Thu', value: 'thu' },
        { title: 'Fri', value: 'fri' },
        { title: 'Sat', value: 'sat' },
      ],
      frequencyOptions: [
        { title: 'every', value: '*' },
        { title: '1st', value: '1' },
        { title: '2nd', value: '2' },
        { title: '3rd', value: '3' },
        { title: '4th', value: '4' },
        { title: '5th', value: '5' },
        { title: 'last', value: 'L' },
      ],
      daysOfMonth: [],
      internalValue: this.getDefaultValue(),
    }
  },
  watch: {
    month() {
      if (this.month) {
        this.daysOfMonth = this.daysInMonth(this.month.index)
      }
    },
  },
  methods: {
    daysInMonth(monthIndex) {
      return new Date(new Date().getFullYear(), monthIndex, 0).getDate()
    },
    getDefaultValue() {
      return {
        days: [],
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
      }
    },
    beforeShow() {
      this.internalValue = {
        ...this.getDefaultValue(),
        ...this.value,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.by-date {
  .card-text {
    margin: 0 25px;
    font-weight: 600;
    font-size: 13px;
  }
  .col {
    max-width: calc(100% / 7);
    padding-top: 6px;

    .cell-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      ::v-deep .custom-checkbox {
        padding-left: 18px;
      }
    }
  }
}

.by-day-of-week {
  .day-of-week-table {
    width: 100%;

    tr {
      height: 30px;

      th:not(:first-child) {
        width: 34px;
      }
      th {
        width: 50px;
        font-size: 13px;
      }
    }
    td{
      max-width: calc(100% / 7)!important;
      padding: 0px!important;
    }
  }
}

::v-deep .modal-dialog-centered {
  @media (min-width: 992px) {
    &.modal-lg, &.modal-xl {
      max-width: 800px!important;
    }
  }
}
::v-deep  .modal-dialog {
    max-width: none;
  }

.hide{
  display: none;
}
</style>
